// @ts-nocheck
export function isCookieExists(cookiesList: Object, name: string){ 
    return Object.values(cookiesList).includes(name)
}

export function setExpirationDays(daysAmount: number) {
    let daysFromToday = new Date();
    daysFromToday.setDate(daysFromToday.getDate() + daysAmount);

    return daysFromToday;
}

export function getCookie(name: string){
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}

export function deleteCookie( name: string , path?: string , domain?: string  ) {
    if( getCookie( name ) ) {
        document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export function getCookies (){
    let pairs = document.cookie.split(";");
    let cookies = {};
    for (const element of pairs){
      let pair = element.split("=");
      cookies[(pair[0]+'').trim()] = pair.slice(1).join('=');
    }
    return cookies;
  }

export function cleanUp(cookiesList: any) {    
    let cookieNames = Object.keys(cookiesList)

    cookieNames.forEach((name) => {
        if (name.match(/.*Adobe/gi) 
        || name.match(/s_.*/gi) 
        || name.match(/QuantumMetric/gi)
        || name.match(/.*CAPTCHA/gi)
        || name.match(/(AEC|NID|DV|OTZ|1P_JAR)/gi)
        || name.match(/utag_.*/gi)) {
            deleteCookie(name)
        }
    })
}