import toast from 'react-hot-toast'

export const showSuccessToast = (msg: string) => {
  toast.remove()
  toast.success(msg, {
    duration: 5000
  })
}

export const showErrorToast = (msg: string) => {
  toast.remove()
  toast.error(msg, {
    duration: 5000
  })
}