import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { QueryClient } from '@tanstack/react-query'
import { showSuccessToast } from '@/utils/toast/toast'
import SessionTimeOutCTA from './SessionTimeout/SessionTimeOut'

const sessionMaxAge = process.env.SESSION_MAX_AGE
  ? parseInt(process.env.SESSION_MAX_AGE)
  : 900000

const promptBeforeIdle = process.env.SESSION_PROMPT_MAX_AGE
  ? parseInt(process.env.SESSION_PROMPT_MAX_AGE)
  : 120000

const idleTimeOutFeatureEnabled: boolean = process.env.FEATURE_SESSION_TIMEOUT
  ? process.env.FEATURE_SESSION_TIMEOUT === 'true'
  : true

const showSessionTimeOutPrompt: boolean = process.env
  .FEATURE_SHOW_SESSION_PROMPT
  ? process.env.FEATURE_SHOW_SESSION_PROMPT === 'true'
  : true

export const IdleTimeWrapper = ({ children }: { children: JSX.Element }) => {
  const router = useRouter()
  const [queryClient] = useState<QueryClient>(() => new QueryClient())
  const { status } = useSession()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [remaining, setRemaining] = useState<number>(0)

  const continueSessionOnClick = () => {
    setIsModalOpen(false)
    activate()
  }

  const userLogoutHandler = async () => {
    queryClient.removeQueries()
    await signOut({ callbackUrl: '/', redirect: false })
    router.push('/')
  }

  const sessionLogoutOnClick = async () => {
    setIsModalOpen(false)
    await userLogoutHandler()
  }

  const runIdleTimer: boolean =
    status === 'authenticated' && idleTimeOutFeatureEnabled

  const { getRemainingTime, activate } = useIdleTimer({
    timeout: sessionMaxAge, // Activity Timeout in milliseconds.
    throttle: 500,
    disabled: !runIdleTimer,
    onIdle: async () => {
      await sessionLogoutOnClick()
      showSuccessToast('User Logged out due to Inactivity')
    },
    onActive: () => {
      setIsModalOpen(false)
    },
    // cross-tab related values
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    // confirm-prompt related values
    promptBeforeIdle, // The amount of milliseconds before timeout to call the onPrompt event handler.
    onPrompt: () => {
      if (showSessionTimeOutPrompt) {
        setIsModalOpen(true)
      }
    },
  })

  useEffect(() => {
    if (runIdleTimer) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)

      return () => {
        clearInterval(interval)
      }
    }
  })

  return (
    <>
      <SessionTimeOutCTA
        isModalOpen={isModalOpen}
        continueSessionOnClick={continueSessionOnClick}
        secondsLeft={remaining}
        sessionLogoutOnClick={sessionLogoutOnClick}
      />
      {children}
    </>
  )
}
