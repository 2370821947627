import { SessionTimeOutCTAFormData } from './SessionTimeOut.model'

export const sessionTimeOutCTAFormValues: SessionTimeOutCTAFormData = {
  modalData: {
    title: 'Your session will end soon.',
    buttonLabels: {
      continue: 'Continue session',
      logout: 'Logout',
    },
  },
}
